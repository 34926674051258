<template>
  <page-view bgColor="#f8f8f8">
    <template v-slot:nav>
      <nav-bar title="推荐详情" left-arrow></nav-bar>
    </template>
    <scroll-view
      height="fullView"
      ref="scroll"
      pullup
      @scrollToEnd="loadMore()"
    >
      <div class="allrecommend-box">
        <div class="detail-box">
          <div class="detail-header">
            <avatar
              shape="square"
              class="useravg"
              :avatarImage="imgFullFix(memberInfo.portrait, 'small')"
              theme="black"
              size="normal"
              :avatarName="memberInfo.name"
            />
            <span class="userinfo">
              <span class="username">{{ memberInfo.name }}</span>
              <span class="usercardid"
                >普通会员 | 卡号：{{ memberInfo.memberNo }}</span
              >
            </span>
            <!-- <span class="userdetailbtn"
                  @click="goToDetail()"
            >会员详情<i class="iconfont2 icon--rightarrow rightarrow"></i></span> -->
          </div>

          <div class="detail-data">
            <div style="background-color: white">
              <span class="font-yellow">{{ memberInfo.recommandCount
              }}<span class="people">人</span></span>
              <span class="font-black">推荐数</span>
            </div>
            <div style="background-color: white">
              <span class="font-red"><span class="danwei">¥</span>{{ memberInfo.recommandSaleAmount }}</span>
              <span class="font-black">推荐客人消费</span>
            </div>
          </div>

          <div class="detail-datalist">
            <ul>
              <li
                v-for="(item, index) in dataList"
                :key="index"
                class="detail-datalist__item van-hairline--bottom"
              >
                <avatar
                  shape="square"
                  class="useravg"
                  :avatarImage="imgFullFix(item.headImg, 'small')"
                  theme="black"
                  size="normal"
                  :avatarName="item.memberName"
                />
                <span class="userinfo">
                  <span style="color: #333333">{{ item.memberName }}</span>
                  <span style="color: #999999">{{ item.phone }}</span>
                </span>
                <!-- <span class="usersex">
                  {{ item.sex }}
                </span> -->
                <span class="usermoney">
                  <price
                    :price="item.orderAmount / 10000"
                    :needSymbol="true"
                    :thousands="false"
                    class="money"
                  />
                  <span class="time">{{ item.createdAt.split(" ")[0] }}</span>
                </span>
              </li>
            </ul>
            <scroll-view-loading :isFinished="isFinished" />
          </div>
        </div>
      </div>
    </scroll-view>
  </page-view>
</template>
<style lang="scss" scoped>
@import "./index.scss";
</style>
<script>
import dayjs from 'dayjs'
import PageView from '@/layouts/PageView'
import ScrollView from '@/components/scroll-view'
import Price from '@/components/price'
import NavBar from '@/components/nav-bar'
import ScrollViewLoading from '@/components/scroll-view-loading'
import Avatar from '@/components/avatar'
import WechatCardModel from '@/model/wechat-card/index'
import { imgFullFix } from '@/utils/img'
export default {
  data () {
    return {
      loading: false, // 加载中
      isFinished: false, // 加载完成
      empty: false,
      dataList: [],
      pageConfig: {
        pageIndex: 1,
        pageSize: 10,
        totalCount: 0
      },
      searchObj: {
        benginDate: '2020-01-01',
        endDate: dayjs().format('YYYY-MM-DD')
      },
      memberInfo: {
        id: '',
        name: '',
        portrait: '',
        recommandSaleAmount: null,
        memberNo: null,
        recommandCount: null
      }
    }
  },
  components: { PageView, ScrollView, Price, NavBar, Avatar, ScrollViewLoading },
  created () {
  },
  mounted () {
    this.getMemberInfo()
    this.loadData(true)
  },
  beforeDestroy () {
  },
  methods: {
    imgFullFix,
    goToDetail () { // 跳转会员详情
      if (!window.$bridge) return
      $bridge.memberInfo.openMemberDetail(this.memberInfo.id) // eslint-disable-line
      // this.$router.push({
      //   path: '/member-detail',
      //   query: {
      //     id: this.memberInfo.id,
      //     name: this.memberInfo.name,
      //     portrait: this.memberInfo.portrait,
      //     recommandSaleAmount: this.memberInfo.recommandSaleAmount,
      //     memberNo: this.memberInfo.memberNo,
      //     recommandCount: this.memberInfo.recommandCount
      //   }
      // })
    },
    getMemberInfo () {
      this.memberInfo = this.$route.query
      console.log('this.memberInfo', this.memberInfo)
    },
    async loadData (isNewPage = false) {
      if (isNewPage) {
        this.dataList = []
        this.pageConfig.pageIndex = 1
        this.pageConfig.totalCount = 0
        this.isFinished = false
        this.empty = false
      }
      const { benginDate, endDate } = this.searchObj
      const start = this.pageConfig.pageIndex
      const limit = this.pageConfig.pageSize
      const { code, data } = await WechatCardModel.getReDetail(this.memberInfo.id, benginDate, endDate, start, limit)
      if (code === 200) {
        this.dataList = this.dataList.concat(data.items)
        this.pageConfig.pageIndex += 1
        this.pageConfig.totalCount = data.totalCounts
        this.isFinished = data.items.length < this.pageConfig.pageSize
        this.empty = (this.pageConfig.pageIndex === 1 && !data.items.length)
        // if (this.isFinished) this.loading = true
      } else {
        throw Error('加载推荐用户列表失败')
      }
      // 数据加载完成之后要进行重新计算当前宽度
      this.$nextTick(() => {
        this.$refs.scroll.refresh()
      })
    },
    // 加载更多数据
    async loadMore () {
      if (this.isFinished || this.loading) return
      this.loading = true
      await this.loadData()
      this.loading = false
    }
  },
  computed: {
  },
  watch: {}
}
</script>
